import orders from '../../../api/modules/orders'

export async function approveAction(id, flag, commentary = '', date = '', reapprove = false) {
  return new Promise((resolve, reject) => {
    try {
      const payload = {}
      if (reapprove) {
        payload.is_reapprove = flag
        payload.is_approved = false
      } else {
        payload.is_approved = flag
      }
      if (flag && !reapprove) {
        date ? (payload.pay_date_planned = date) : ''
      }
      commentary ? (payload.commentary = commentary || '') : ''
      orders.approve(id, payload).then(() => {
        resolve()
      })
    } catch (err) {
      console.log(err)
      reject()
    }
  })
}
